<script>


import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import { mapActions, mapGetters, mapState } from 'vuex';
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import moment from "moment";
import {
  required,
  email,
  helpers,
  numeric,
  maxLength
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import SignaturePad from "signature_pad"


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Setting",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Setting",
      items: [{
        text: "Pages",
        href: "/",
      },
      {
        text: "Setting",
        active: true,
      },
      ],
      value: ["javascript"],
      date: null,
      assetUrl: process.env.VUE_APP_ENVIRONMENT != 'local' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_URL,
      isSubmited: false,
      loader: false,
      disabled: false,
      baseURl: null,
      user: {
        name: "",
        email: "",
        phone: "",
        language: "english",
        visible_password: "",
        qualification: '',
        date_of_birth: '',
        role_id: null,
        business_name: "",
        business_type: "",
        bank_account: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "india",
        years_of_experience: "",
        passport_photo: '',
        passport_photo_url: '',
        aadhar_card: '',
        aadhar_card_url: '',
        tier: "",
        consultancy_charge_fifthteen: "",
        consultancy_charge_thirty: "",
        consultancy_charge_forty: "",
        skills: [],
        industries: [],

        bio: '',
        pan_no: '',
        pan_name: '',
        gst_no: '',
        signature: '',
        pan_photo: '',
        gst_photo: '',

        signature_photo: '',
        qa_doc: ''
      },
      isUpdateSignature: false,
      signature: null
    };
  },
  computed: {
    ...mapGetters({
      getSkills: "skills/getSkills",
      getIndustries: "industry/getIndustry",
      getServices: "service/getServices",
      getProfileAttachments: "profile/getProfileAttachments",
    }),
    ...mapState('auth', {
      userData: state => state.user,
    }),
    profileType() {
      if (this.userData.role_id == 1) {
        return 'Super Admin'
      } else if (this.userData.role_id == 2) {
        return 'Team'
      } else if (this.userData.role_id == 3) {
        return 'User'
      }
      return null;
    },
    skillsOptions() {
      var options = []
      this.getSkills.map(s => {
        var payload = {
          value: s.skills_id,
          label: s.skill_name,
        }
        options.push(payload)
      })
      return options
    },
    industryOptions() {
      var options = []
      this.getIndustries.map(i => {
        var payload = {
          value: i.industries_id,
          label: i.industries_name,
        }
        options.push(payload)
      })
      return options
    },
    serviceOptions() {
      var options = []
      this.getServices.map(i => {
        var payload = {
          value: i.id,
          label: i.title,
        }
        options.push(payload)
      })
      return options
    },
  },
  validations: {
    user: {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      phone: {
        required: helpers.withMessage("Phone is required", required),
        numeric: helpers.withMessage("Please enter only numbers", numeric),
      },
      visible_password: {
        required: helpers.withMessage("Password is required", required),
      },
      role_id: {
        required: helpers.withMessage("Role is required", required),
      },
      country: {
        required: helpers.withMessage("Country is required", required),
      },
      years_of_experience: {
        required: helpers.withMessage("City is required", required),
      },
      pan_name: {
        required: helpers.withMessage("PAN name is required", required),
      },
      pan_no: {
        required: helpers.withMessage("PAN number is required", required),
      },
      gst_no: {
        required: helpers.withMessage("GST number is required", required),
      },
      qualification: {
        required: helpers.withMessage("Bio is required", required),
      },
      date_of_birth: {
        required: helpers.withMessage("Bio is required", required),
      },
      bio: {
        required: helpers.withMessage("Bio is required", required),
        maxLength: helpers.withMessage("Maximum 700 Characters", maxLength(700)),
      },
    }
  },
  created() {
    this.setProfileAttachments()
    this.setUsersData()
    this.setSkills();
    this.setIndustries();
    this.setServices();
  },
  mounted() {
    this.baseURl = process.env.VUE_APP_API_URL;
    // this.signature = new SignaturePad(this.$refs.signatureCanvas);
    // this.signature.dotSize = 0.2
  },
  methods: {
    ...mapActions({
      setProfileAttachments: "profile/setProfileAttachments",
      updateUserDb: 'users/updateUser',
      setUserData: 'auth/setUserData',
      setSkills: "skills/setSkills",
      setIndustries: "industry/setIndustry",
      updatePartner: 'auth/updatePartner',
      setServices: "service/setServices",
    }),
    getDate(date) {
      return moment(date).format("MM/DD/YY");
    },
    updateUser() {
      console.log('submit');
      this.isSubmited = true;
      this.v$.user.$touch();
      if (this.v$.user.$invalid) {
        return;
      }
      this.loader = true
      this.disabled = true
      var formdata = new FormData();
      formdata.append('id', this.user.id);
      formdata.append('name', this.user.name);
      formdata.append('email', this.user.email);
      formdata.append('password', this.user.visible_password);
      formdata.append('phone', this.user.phone);
      formdata.append('qualification', this.user.qualification);
      formdata.append('date_of_birth', this.user.date_of_birth);
      formdata.append('city', this.user.city);
      formdata.append('state', this.user.state);
      formdata.append('country', this.user.country);
      formdata.append('address', this.user.address);
      formdata.append('zip', this.user.zip);
      formdata.append('years_of_experience', this.user.years_of_experience);
      formdata.append('role_id', this.user.role_id);
      formdata.append('passport_photo', this.user.passport_photo);
      formdata.append('aadhar_card', this.user.aadhar_card);
      formdata.append('pan_photo', this.user.pan_photo);
      formdata.append('gst_photo', this.user.gst_photo);
      formdata.append('consultancy_charge_fifthteen', this.user.consultancy_charge_fifthteen);
      formdata.append('consultancy_charge_thirty', this.user.consultancy_charge_thirty);
      formdata.append('consultancy_charge_forty', this.user.consultancy_charge_forty);
      formdata.append('skills', JSON.stringify(this.user.skills));
      formdata.append('industries', JSON.stringify(this.user.industries));
      formdata.append('services', JSON.stringify(this.user.services));

      formdata.append('bio', this.user.bio);
      formdata.append('pan_no', this.user.pan_no);
      formdata.append('pan_name', this.user.pan_name);
      formdata.append('gst_no', this.user.gst_no);
      formdata.append('signature', this.user.signature_photo);
      formdata.append('qa_doc', this.user.qa_doc);

      if (this.user.tier)
        formdata.append('tier', this.user.tier);
      // if (!this.signature.isEmpty()) {
      //   var signatureData = this.signature.toDataURL('image/png');
      //   formdata.append('signature', signatureData);
      // }
      this.updatePartner(formdata).then((res) => {
        this.loader = false
        this.disabled = false
        console.log('resss', res)
        if (res.status == 200) {
          var userDb = res.data.user
          var userDetails = res.data.user_details
          if (userDetails) {
            this.user.pan_photo = userDetails.pan_photo;
            this.user.passport_photo = userDetails.passport_photo;
            this.user.gst_photo = userDetails.gst_photo;
            this.user.aadhar_card = userDetails.aadhar_card;
          }
          this.setUserData(userDb)

          this.$toast.open({
            message: "Profile successfully.",
            type: "success",
          })
        } else {
          let message = res.data.message
          this.$toast.open({
            message: message,
            type: "success",
          })
        }
      }).catch((error) => {
        this.loader = false
        this.disabled = false
        if (error.response && error.response.status == 422 && error.response.data && error.response.data.data.email) {
          this.$toast.open({
            message: 'The email has already been taken.',
            type: "error",
          })
        } else if (error.response && error.response.status == 422 && error.response.data && error.response.data.data.phone) {
          this.$toast.open({
            message: 'The phone has already been taken.',
            type: "error",
          })
        } else {
          this.$toast.open({
            message: 'Server Error',
            type: "error",
          })
        }
      })
    },
    changeFile(e, type) {
      const file = e.target.files[0];
      if (file) {
        if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif' || file.type == 'application/pdf') {
          if (type == 'pan') {
            this.user.pan_photo = file
          } else if (type == 'gst') {
            this.user.gst_photo = file
          } else if (type == 'aadhar') {
            this.user.aadhar_card = file
            this.user.aadhar_card_url = URL.createObjectURL(file);
          } else if (type == 'passport') {
            this.user.passport_photo = file
            this.user.passport_photo_url = URL.createObjectURL(file);
          }
          else if (type == 'signature') {
            this.user.signature_photo = file
          }
          else if (type == 'qaDoc') {
            this.user.qa_doc = file
          }
        } else {
          console.log(
            "Error!",
            "Please enter valid image file.",
            "error"
          );
        }
      }
    },
    // changePassportFile(e) {
    //   const file = e.target.files[0];
    //   if (file) {
    //     if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif') {
    //         this.user.passport_photo = file
    //         this.user.passport_photo_url = URL.createObjectURL(file);

    //     } else {
    //       console.log(
    //           "Error!",
    //           "Please enter valid image file.",
    //           "error"
    //       );
    //     }
    //   }
    // },

    // changeAadharFile(e) {
    //   const file = e.target.files[0];
    //   if (file) {
    //     if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif') {
    //         this.user.aadhar_card = file
    //         this.user.aadhar_card_url = URL.createObjectURL(file);

    //     } else {
    //       console.log(
    //           "Error!",
    //           "Please enter valid image file.",
    //           "error"
    //       );
    //     }
    //   }
    // },
    nameInitials(name) {
      var nameArray = name.split(" ");
      var initials = '';
      if (nameArray.length === 1) {
        return nameArray[0].charAt(0) + "" + nameArray[0].charAt(1);
      } else {
        initials = nameArray[0].charAt(0);
      }
      for (var i = (nameArray.length - 1); i < nameArray.length; i++) {
        initials += nameArray[i].charAt(0);
      }
      return initials.toUpperCase();
    },
    setUsersData() {
      console.log('userData', this.userData)
      this.user = {
        // date_of_birth = res.data.data.user.date_of_birth.substr(0, res.data.data.user.date_of_birth.indexOf(' ')),
        id: this.userData.id,
        name: this.userData.name,
        email: this.userData.email,
        phone: this.userData.phone,
        language: this.userData.language && this.userData.language ? this.userData.language : 'english',
        password: this.userData.password,
        visible_password: this.userData.visible_password,
        role_id: this.userData.role_id,
        address: this.userData.user_businesses && this.userData.user_businesses.address ? this.userData.user_businesses.address : null,
        city: this.userData.user_businesses && this.userData.user_businesses.city ? this.userData.user_businesses.city : null,
        state: this.userData.user_businesses && this.userData.user_businesses.state ? this.userData.user_businesses.state : null,
        zip: this.userData.user_businesses && this.userData.user_businesses.zip ? this.userData.user_businesses.zip : null,
        country: this.userData.user_businesses && this.userData.user_businesses.country ? this.userData.user_businesses.country : 'india',
        years_of_experience: this.userData.user_details && this.userData.user_details.years_of_experience ? this.userData.user_details.years_of_experience : null,
        passport_photo: this.userData.user_details && this.userData.user_details.passport_photo ? this.userData.user_details.passport_photo : null,
        passport_photo_url: '',
        aadhar_card: this.userData.user_details && this.userData.user_details.aadhar_card ? this.userData.user_details.aadhar_card : null,
        signature: this.userData.user_details && this.userData.user_details.signature ? this.userData.user_details.signature : null,
        pan_photo: this.userData.user_details && this.userData.user_details.pan_photo ? this.userData.user_details.pan_photo : null,
        gst_photo: this.userData.user_details && this.userData.user_details.gst_photo ? this.userData.user_details.gst_photo : null,
        aadhar_card_url: '',
        bio: this.userData.user_details && this.userData.user_details.bio ? this.userData.user_details.bio : null,
        pan_no: this.userData.user_details && this.userData.user_details.pan_number ? this.userData.user_details.pan_number : null,
        pan_name: this.userData.user_details && this.userData.user_details.pan_name ? this.userData.user_details.pan_name : null,
        gst_no: this.userData.user_details && this.userData.user_details.gst_number ? this.userData.user_details.gst_number : null,
        tier: this.userData.user_details && this.userData.user_details.tier ? this.userData.user_details.tier : null,
        qualification: this.userData.user_details && this.userData.user_details.qualification ? this.userData.user_details.qualification : null,
        date_of_birth: this.userData.user_details && this.userData.user_details.date_of_birth ? this.userData.user_details.date_of_birth.substr(0, this.userData.user_details.date_of_birth.indexOf(' ')) : null,
        consultancy_charge_fifthteen: this.userData.user_details && this.userData.user_details.consultancy_charge_fifthteen ? this.userData.user_details.consultancy_charge_fifthteen : null,
        consultancy_charge_thirty: this.userData.user_details && this.userData.user_details.consultancy_charge_thirty ? this.userData.user_details.consultancy_charge_thirty : null,
        consultancy_charge_forty: this.userData.user_details && this.userData.user_details.consultancy_charge_forty ? this.userData.user_details.consultancy_charge_forty : null,
      }

      if (!this.user.skills) this.user.skills = []
      if (!this.user.industries) this.user.industries = []
      if (!this.user.services) this.user.services = []

      if (this.userData.user_skills) {
        var skillsIds = this.userData.user_skills.map(function (s) { return s.skill_id })
        this.user.skills = skillsIds
      }
      if (this.userData.user_industries) {
        var industriesIds = this.userData.user_industries.map(function (i) { return i.industry_id })
        this.user.industries = industriesIds
      }
      if (this.userData.user_services) {
        var servicesIds = this.userData.user_services.map(function (s) { return s.service_id })
        this.user.services = servicesIds
      }
    },

    showSelectedArr(fieldCom, fieldVar) {
      return fieldCom?.filter((singleSkill) => {
        return fieldVar.includes(singleSkill.value)
      })
    },
    redirectToPublicProfile() {
      var userSlug = this.userData.name.replace(/\s+/g, '-') + '~' + this.userData.id
      // this.$router.push({ path: `/profile/${userSlug}` })
      var assetUrl = window.location.origin
      var Url = assetUrl + `/profile/${userSlug}`
      window.open(Url, '_blank');
    }
  },
  components: {
    Layout, Multiselect
  },
};
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img src="@/assets/images/profile-bg.jpg" class="profile-wid-img" alt="" />
        <!-- <div class="overlay-content">
          <div class="text-end p-3">
            <div class="p-0 ms-auto rounded-circle profile-photo-edit">
              <input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input" />
              <label for="profile-foreground-img-file-input" class="profile-photo-edit btn btn-light">
                <i class="ri-image-edit-line align-bottom me-1"></i> Change
                Cover
              </label>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                  mb-4
                ">

              </div>
              <div class="mx-auto mb-2">
                <img :src="`${baseURl}/${user.passport_photo}`" v-if="user.passport_photo && user" alt="user-img"
                  style="" class="img-thumbnail" />
              </div>
              <h5 class="fs-16 mt-3 mb-1">{{ userData.name }}</h5>
              <p class="text-muted mb-0">{{ profileType }}</p>
              <h5 class="text-muted mb-0">Customer #: 100785{{ userData.id }}</h5>

              <div class="mt-4 fs-5 text-primary">
                <a @click="redirectToPublicProfile" style="cursor: pointer;">
                  <svg xmlns="http://www.w3.org/2000/svg" height="0.8em" class="me-1" viewBox="0 0 512 512"
                    style="fill: #0076CE;"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                  </svg>
                  View public profile
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              " role="tablist">
              <li class="nav-item">
                <a class="nav-link active text-body" data-bs-toggle="tab" href="#personalDetails" role="tab">
                  <i class="fas fa-home"></i>
                  {{ $t("profile.personal-details") }}
                </a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link text-body" data-bs-toggle="tab" href="#changePassword" role="tab">
                  <i class="far fa-user"></i>
                  Change Password
                </a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link text-body" data-bs-toggle="tab" href="#download" role="tab">
                  <i class="far fa-envelope"></i>
                  {{ $t("profile.download") }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="personalDetails" role="tabpanel">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="nameInput" class="form-label">{{ userData.role_id == 4 ? 'Name of authorised person'
                            : $t('user.name') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" id="nameInput" :placeholder="$t('user.enter-your-name')"
                            v-model="user.name" :class="{
                              'is-invalid': isSubmited && v$.user.name.$error,
                            }" />
                          <div v-for="(item, index) in v$.user.name.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="leaveemails" class="form-label">{{ $t('user.email') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="email" class="form-control" id="leaveemails"
                            :placeholder="$t('user.enter-your-email')" v-model="user.email" :class="{
                              'is-invalid': isSubmited && v$.user.email.$error,
                            }" />
                          <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="phone" class="form-label">{{ $t('user.phone') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" id="phone" :placeholder="$t('user.enter-your-phone')"
                            v-model="user.phone" :class="{
                              'is-invalid': isSubmited && v$.user.phone.$error,
                            }" />
                          <div v-for="(item, index) in v$.user.phone.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="password" class="form-label">{{ $t('user.password') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" id="password"
                            :placeholder="$t('user.enter-your-password')" v-model="user.visible_password" :class="{
                              'is-invalid': isSubmited && v$.user.visible_password.$error,
                            }" />
                          <div v-for="(item, index) in v$.user.visible_password.$errors" :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>


                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="bio" class="form-label">Bio</label>
                        </div>
                        <div class="col-lg-9">
                          <textarea class="form-control" id="address" rows="3" placeholder="Enter your bio"
                            v-model="user.bio"></textarea>
                          <div class="text-end" v-if="user.bio.length > 0">{{ 700 - user.bio.length }} character left</div>
                          <div v-for="(item, index) in v$.user.bio.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>


                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="date_of_birth" class="form-label">Date of Birth </label>
                        </div>
                        <div class="col-lg-9">
                          <input type="date" class="form-control" v-model="user.date_of_birth" id="date_of_birth" :class="{
                            'is-invalid': isSubmited && v$.user.date_of_birth.$error,
                          }" placeholder="Enter Date of Birth" />
                          <div v-for="(item, index) in v$.user.date_of_birth.$errors" :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="qualification" class="form-label">Qualification</label>
                        </div>
                        <div class="col-lg-9">
                          <select class="form-select" aria-label="Default select example" v-model="user.qualification"
                            id="qualification" :class="{
                              'is-invalid': isSubmited && v$.user.qualification.$error,
                            }">
                            <option disabled value="">--Select--</option>
                            <option value="Chartered Accountant">Chartered Accountant</option>
                            <option value="Certified Public Accountant(CPA)">Certified Public Accountant(CPA)</option>
                            <option value="Company Secretary">Company secretary</option>
                            <option value="Cost Accountant">Cost accountant</option>
                            <option value="Advocate">Advocate</option>

                            <option value="BBA_MBA" v-if="user.role_id == 5">BBA/ MBA</option>
                            <option value="M COM" v-if="user.role_id == 5">M COM</option>
                            <option value="B COM" v-if="user.role_id == 5">B COM</option>

                            <option value="IRS" v-if="user.role_id == 4">IRS</option>
                            <option value="Land and Building" v-if="user.role_id == 4">Registered Valuer- Land and
                              Building</option>
                            <option value="Securities consultant" v-if="user.role_id == 4">Registered Valuer- Securities
                              consultant</option>

                            <option value="B COM">Other</option>
                          </select>

                          <div v-for="(item, index) in v$.user.qualification.$errors" :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="bio" class="form-label">Bio</label>
                        </div>
                        <div class="col-lg-9">
                          <textarea class="form-control" id="address" rows="3" placeholder="Enter your bio"
                            v-model="user.bio"></textarea>
                        </div>
                      </div> -->

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="address" class="form-label">{{ $t('user.address') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <textarea class="form-control" id="address" rows="3"
                            :placeholder="$t('user.enter-your-address')" v-model="user.address"></textarea>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="city" class="form-label">{{ $t('user.city') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" id="city" :placeholder="$t('user.enter-city')"
                            v-model="user.city" />
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="state" class="form-label">{{ $t('user.state') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" id="state" :placeholder="$t('user.enter-state')"
                            v-model="user.state" />
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="zipcode" class="form-label">{{ $t('user.zip') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" id="zipcode" :placeholder="$t('user.enter-zip')"
                            v-model="user.zip" />
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="country" class="form-label">{{ $t('user.country') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <Multiselect v-model="user.country" :class="{
                            'is-invalid': isSubmited && v$.user.country.$error,
                          }" :close-on-select="true" :create-option="true" :clearable="false" :searchable="true"
                            :options="[
                              { value: 'india', label: 'India' },
                              { value: 'uae', label: 'UAE' },
                            ]" />
                          <div v-for="(item, index) in v$.user.country.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="years_of_experience" class="form-label">Years of Experience</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" v-model="user.years_of_experience"
                            id="years_of_experience" :class="{
                              'is-invalid': isSubmited && v$.user.years_of_experience.$error,
                            }" placeholder="Enter Years of Experience" />
                          <div v-for="(item, index) in v$.user.years_of_experience.$errors" :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="pan_name" class="form-label">Name as per PAN</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" v-model="user.pan_name" id="pan_name" :class="{
                            'is-invalid': isSubmited && v$.user.pan_name.$error,
                          }" placeholder="Enter Name as per PAN" />
                          <div v-for="(item, index) in v$.user.pan_name.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="pan_no" class="form-label">PAN No.</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" v-model="user.pan_no" id="pan_no" :class="{
                            'is-invalid': isSubmited && v$.user.pan_no.$error,
                          }" placeholder="Enter PAN no" />
                          <div v-for="(item, index) in v$.user.pan_no.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="gst_no" class="form-label">GST No.</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" v-model="user.gst_no" id="gst_no" :class="{
                            'is-invalid': isSubmited && v$.user.gst_no.$error,
                          }" placeholder="Enter GST no" />
                          <div v-for="(item, index) in v$.user.gst_no.$errors" :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row mb-4">
                  <div class="col-lg-3">
                    <label class="form-label"
                      >Regsitration Type</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <input type="radio" id="consultant" name="role" v-model="user.role_id" :value="4">
                        <label for="consultant" class="form-label mb-0 mx-2">Consultant</label>
                      </div>
                      <div class="d-flex align-items-center mx-2">
                        <input type="radio" id="accountant" name="role" v-model="user.role_id" :value="5">
                        <label for="accountant" class="form-label mb-0 mx-2">Qualified Accountant</label>
                      </div>
                    </div>
                  </div>
                </div> -->

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label class="form-label">Passport Size Photo</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="file" id="myFile" name="filename" @change="changeFile($event, 'passport')">
                          <a :href="`${baseURl}/${user.passport_photo}`" target="_blank" v-if="user.passport_photo">View
                           </a>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label class="form-label">Professional ID Card:</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="file" id="myFile" name="filename" @change="changeFile($event, 'aadhar')">
                          <a :href="`${baseURl}/${user.aadhar_card}`" target="_blank" v-if="user.aadhar_card">View</a>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label class="form-label">PAN no.</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="file" id="myFile" name="filename" @change="changeFile($event, 'pan')">
                          <a :href="`${baseURl}/${user.pan_photo}`" target="_blank" v-if="user.pan_photo">View</a>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label class="form-label">GST no.</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="file" id="myFile" name="filename" @change="changeFile($event, 'gst')">
                          <a :href="`${baseURl}/${user.gst_photo}`" target="_blank" v-if="user.gst_photo">View</a>
                        </div>
                      </div>

                    </div>

                    <div class="col-6">

                      <div class="row mb-4" v-if="user.role_id == 1 || user.role_id == 2">
                        <div class="col-lg-3">
                          <label for="phone" class="form-label">{{ $t('user.role') }}</label>
                        </div>
                        <div class="col-lg-9">
                          <div class="mt-4 mt-lg-0">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="role-radio" id="consultant" value="4"
                                v-model="user.role_id" :class="{
                                  'is-invalid': isSubmited && v$.user.role_id.$error,
                                }" />
                              <label class="form-check-label" for="user">Consultant </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="role-radio" id="qa" value="5"
                                v-model="user.role_id" :class="{
                                  'is-invalid': isSubmited && v$.user.role_id.$error,
                                }" />
                              <label class="form-check-label" for="user">QA</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label for="phone" class="form-label">Signature</label>
                        </div>

                        <div class="col-lg-9" v-show="isUpdateSignature == true || !user.signature">
                          <!-- <canvas ref="signatureCanvas" id="signatureCanvas" class="signature-pad col-md-6" />
                          <button class="
                          btn
                          btn-soft-dark
                          align-items-center
                          d-flex
                          justify-content-center
                        " type="button" @click="signature.clear()">
                            Clear Signature
                          </button> -->
                          <input type="file" id="myFile" name="filename" @change="changeFile($event, 'signature')">
                        </div>
                        <div class="col-lg-9" v-if="!isUpdateSignature && user.signature">
                          <div class="signature-pad position-relative" style="max-width: 250px; aspect-ratio: 16/9;">
                            <span @click="isUpdateSignature = true"
                              class="position-absolute top-0 start-100 d-grid translate-middle bg-danger text-white rounded-circle"
                              style="height: 20px; width: 20px; place-items: center; cursor: pointer;">x
                            </span>
                            <img :src="`${baseURl}/${user.signature}`" style="width: 100%;"
                              :alt="`${baseURl}/${user.signature}`">
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4" v-if="user.role_id == 1 || user.role_id == 2">
                        <div class="col-lg-3">
                          <label for="tier" class="form-label">Tier</label>
                        </div>
                        <div class="col-lg-9">
                          <div class="mt-4 mt-lg-0">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="tier1-radio" id="tier1" value="1"
                                v-model="user.tier" :class="{
                                  'is-invalid': isSubmited && v$.user.tier.$error,
                                }" />
                              <label class="form-check-label" for="super-admin">Tier 1</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="tier2-radio" id="tier2" value="2"
                                v-model="user.tier" :class="{
                                  'is-invalid': isSubmited && v$.user.tier.$error,
                                }" />
                              <label class="form-check-label" for="super-admin">Tier 2</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="tier3-radio" id="tier3" value="3"
                                v-model="user.tier" :class="{
                                  'is-invalid': isSubmited && v$.user.tier.$error,
                                }" />
                              <label class="form-check-label" for="super-admin">Tier 3</label>
                            </div>

                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="tier4-radio" id="tier4" value="4"
                                v-model="user.tier" :class="{
                                  'is-invalid': isSubmited && v$.user.tier.$error,
                                }" />
                              <label class="form-check-label" for="super-admin">Tier 4</label>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div v-if="user.role_id == 4">
                        <!-- v-else-if="user.role_id == 5" -->
                        <div class="row mb-4">
                          <div class="col-lg-3">
                            <label for="charges" class="form-label">Consultancy Charges</label>
                          </div>
                          <div class="col-lg-9">
                            <div class="d-flex align-items-center mb-3">
                              <input class="form-control small-input me-2" type="number"
                                v-model="user.consultancy_charge_fifthteen"> / 15 Minutes
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <input class="form-control small-input me-2" type="number"
                                v-model="user.consultancy_charge_thirty"> / 30 Minutes
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <input class="form-control small-input me-2" type="number"
                                v-model="user.consultancy_charge_forty"> / 45 Minutes
                            </div>
                          </div>
                        </div>

                        <div class="row mb-4">
                          <div class="col-lg-3">
                            <label for="phone" class="form-label">Select Skills</label>
                          </div>
                          <div class="col-lg-9">
                            <Multiselect max="3" mode="multiple" v-model="user.skills" :preserve-search="true"
                              :close-on-select="true" :clearable="false" :searchable="true" :create-option="true"
                              :options="skillsOptions" />

                            <div class="mt-1">
                              <span v-for="(selectedSkill, index) in showSelectedArr(skillsOptions, user.skills)"
                                :key="index">{{ selectedSkill.label }} <span
                                  v-if="!(showSelectedArr(skillsOptions, user.skills).length == index + 1)">,</span></span>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-4">
                          <div class="col-lg-3">
                            <label for="phone" class="form-label">Select Industries</label>
                          </div>
                          <div class="col-lg-9">
                            <Multiselect max="3" mode="multiple" :clear-on-select="false" v-model="user.industries"
                              :close-on-select="true" :create-option="true" :clearable="false" :searchable="true"
                              :options="industryOptions" />

                            <div class="mt-1">
                              <span v-for="(selectedSkill, index) in showSelectedArr(industryOptions, user.industries)"
                                :key="index">{{ selectedSkill.label }} <span
                                  v-if="!(showSelectedArr(industryOptions, user.industries).length == index + 1)">,</span></span>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div v-else-if="user.role_id == 5">
                        <div class="row mb-4">
                          <div class="col-lg-3">
                            <label for="phone" class="form-label">Select Service</label>
                          </div>
                          <div class="col-lg-9">
                            <Multiselect mode="multiple" :clear-on-select="false" v-model="user.services"
                              :close-on-select="true" :create-option="true" :clearable="false" :searchable="true"
                              :options="serviceOptions" />

                            <div class="mt-1">
                              <span v-for="(selectedSkill, index) in showSelectedArr(serviceOptions, user.services)"
                                :key="index">{{ selectedSkill.label }} <span
                                  v-if="!(showSelectedArr(serviceOptions, user.services).length == index + 1)">,</span></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-3">
                          <label class="form-label">Qualification Document</label>
                        </div>
                        <div class="col-lg-9">
                          <input type="file" id="myFile" name="filename" @change="changeFile($event, 'qaDoc')">
                        </div>
                      </div>

                    </div>
                  </div>


                  <div class="text-end float-end">
                    <button class="btn btn-primary align-items-center d-flex justify-content-center" :disabled="disabled"
                      @click="updateUser">
                      {{ $t('user.update-user') }}
                      <div class="spinner-border loader-setup" role="status" v-if="loader">
                        <span class="sr-only">{{ $t('t-loading') }}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="oldpasswordInput" class="form-label">Old Password*</label>
                        <input type="password" class="form-control" id="oldpasswordInput"
                          placeholder="Enter current password" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label">New Password*</label>
                        <input type="password" class="form-control" id="newpasswordInput"
                          placeholder="Enter new password" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label">Confirm Password*</label>
                        <input type="password" class="form-control" id="confirmpasswordInput"
                          placeholder="Confirm password" />
                      </div>
                    </div>
                    <!--end col-->
                    <!-- <div class="col-lg-12">
                      <div class="mb-3">
                        <a href="javascript:void(0);" class="link-primary text-decoration-underline">Forgot Password
                          ?</a>
                      </div>
                    </div> -->
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success">
                          Change Password
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="download" role="tabpanel">
                <div class="history" v-if="getProfileAttachments.length > 0">
                  <div class="card-body px-0">
                    <div class="table-responsive table-bordered">
                      <table class="table align-middle table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col"> {{ $t('order.date') }}</th>
                            <th scope="col">{{ $t('order.comment') }}</th>
                            <th scope="col">{{ $t('order.attachment') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(attachment, index) in getProfileAttachments" :key="index">
                            <td>{{ getDate(attachment.created_at) }}</td>
                            <td>{{ attachment.notes }}</td>
                            <td>
                              <a v-if="attachment.attachment"
                                :href="`https://e-triq.com/api/triq-backend-laravel/storage/app/public/${attachment.attachment}`"
                                download="" target="_blank">Download</a>
                              <span v-else>--</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
<style>
.signature-pad {
  border: 1px solid grey;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 0px;
}
</style>
<style scoped>
.small-input {
  width: 100px;
}
</style>